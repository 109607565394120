import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const TermsConditionsPage = () => {
  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>TERMS AND CONDITIONS</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="content">
                  <div class="row">
                    <div class="col-md-12">
                      <p>
                        This website is operated by the H-C Group and all other
                        affiliates of the Heavenly-Candy Brand. In general,{" "}
                        <Link className="text-purple" href="/">
                          www.heavenly-candy.com
                        </Link>{" "}
                        owns and operates this website. This document governs
                        your relationship with{" "}
                        <Link className="text-purple" href="/">
                          www.heavenly-candy.com
                        </Link>
                        . Access to and use of this website and the products and
                        services available through this website are subject to
                        the following terms, conditions and notices. By using
                        the services, you are agreeing to all of the Terms of
                        Service, as may be updated by us from time to time. Any
                        dispute arising out of these terms and agreement will be
                        solved in the EU country of England. By agreeing to
                        these Terms of Service, you represent that you are at
                        least the age of majority in your state or province of
                        residence, or that you are the age of majority in your
                        state or province of residence and you have given us
                        your consent to allow any of your minor dependents to
                        use this site. You may not use our products for any
                        illegal or unauthorized purpose nor may you, in the use
                        of the Service, violate any laws in your jurisdiction
                        (including but not limited to copyright laws). You must
                        not transmit any worms or viruses or any code of a
                        destructive nature. A breach or violation of any of the
                        Terms will result in an immediate termination of your
                        Services.
                      </p>
                      <p>
                        We reserve the right to refuse service to anyone for any
                        reason at any time. You understand that your content
                        (not including credit card information), may be
                        transferred unencrypted and involve (a) transmissions
                        over various networks; and (b) changes to conform and
                        adapt to technical requirements of connecting networks
                        or devices. Credit card information is always encrypted
                        during transfer over networks. We shall not be liable to
                        you or to any third-party for any modification, price
                        change, suspension or discontinuance of the Service.
                        Certain products or services may be available
                        exclusively online through the website. These products
                        or services may have limited quantities and are subject
                        to return or exchange only according to our Return
                        Policy.
                      </p>
                      <p>
                        You should check this page regularly to take notice of
                        any changes we may have made to the Terms of Service. If
                        you do not agree to all the terms and conditions of this
                        agreement, then you may not access the website or use
                        any services. Access to this website is permitted on a
                        temporary basis, and we reserve the right to withdraw or
                        amend the Services without notice. We will not be liable
                        if for any reason this website is unavailable at any
                        time or for any period. From time to time, we may
                        restrict access to some parts or this entire website.
                        This website may contain links to other websites, which
                        are not operated by{" "}
                        <Link className="text-purple" href="/">
                          {""}
                          {""}www.heavenly-candy.com
                        </Link>{" "}
                        has no control over the Linked Sites and accepts no
                        responsibility for them or for any loss or damage that
                        may arise from your use of them. Your use of the Linked
                        Sites will be subject to the terms of use and service
                        contained within each such site.
                      </p>
                      <div class="col mt-5">
                        <div class="tabs text-left">
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd1"
                              name="rd"
                            />
                            <label class="tab-label" for="rd1">
                              Prohibitions
                            </label>
                            <div class="tab-content">
                              You must not misuse this website. You will not:
                              commit or encourage a criminal offense; transmit
                              or distribute a virus, trojan, worm, logic bomb or
                              any other material which is malicious,
                              technologically harmful, in breach of confidence
                              or in any way offensive or obscene; hack into any
                              aspect of the Service; corrupt data; cause
                              annoyance to other users; infringe upon the rights
                              of any other person's proprietary rights; send any
                              unsolicited advertising or promotional material,
                              commonly referred to as "spam"; or attempt to
                              affect the performance or functionality of any
                              computer facilities of or accessed through this
                              website. Breaching this provision would constitute
                              a criminal offense and www.heavenlycandy.com will
                              report any such breach to the relevant law
                              enforcement authorities and disclose your identity
                              to them. We will not be liable for any loss or
                              damage caused by a distributed denial-of-service
                              attack, viruses or other technologically harmful
                              material that may infect your computer equipment,
                              computer programs, data or other proprietary
                              material due to your use of this website or to
                              your downloading of any material posted on it, or
                              on any website linked to it.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd2"
                              name="rd"
                            />
                            <label class="tab-label" for="rd2">
                              Intellectual Property, Software and Content
                            </label>
                            <div class="tab-content">
                              The intellectual property rights in all software
                              and content (including photographic images) made
                              available to you on or through this website
                              remains the property of www.heavenlycandy.com or
                              its licensors and are protected by copyright laws
                              and treaties around the world. All such rights are
                              reserved by{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              and its licensors. You may store, print and
                              display the content supplied solely for your own
                              personal use. You are not permitted to publish,
                              manipulate, distribute or otherwise reproduce, in
                              any format, any of the content or copies of the
                              content supplied to you or which appears on this
                              website nor may you use any such content in
                              connection with any business or commercial
                              enterprise.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd3"
                              name="rd"
                            />
                            <label class="tab-label" for="rd3">
                              Terms of Sale
                            </label>
                            <div class="tab-content">
                              By placing an order you are offering to purchase a
                              product on and subject to the following terms and
                              conditions. All orders are subject to availability
                              and confirmation of the order price. Dispatch
                              times may vary according to availability and
                              subject to any delays resulting from postal delays
                              or force majeure for which we will not be
                              responsible. In order to contract with{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              you must be over 18 years of age and possess a
                              valid credit or debit card issued by a bank
                              acceptable to us. www.heavenlycandy.com retains
                              the right to refuse any request made by you. If
                              your order is accepted we will inform you by email
                              and we will confirm the identity of the party
                              which you have contracted with. This will usually
                              be{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              or may in some cases be a third party. Where a
                              contract is made with a third party{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              is not acting as either agent or principal and the
                              contract is made between yourself and that third
                              party and will be subject to the terms of sale
                              which they supply you. When placing an order you
                              undertake that all details you provide to us are
                              true and accurate, that you are an authorized user
                              of the credit or debit card used to place your
                              order and that there are sufficient funds to cover
                              the cost of the goods. The cost of foreign
                              products and services may fluctuate. All prices
                              advertised are subject to such changes.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd4"
                              name="rd"
                            />
                            <label class="tab-label" for="rd4">
                              (a) Our Contract
                            </label>
                            <div class="tab-content">
                              When you place an order, you will receive an
                              acknowledgement e-mail confirming receipt of your
                              order: this email will only be an acknowledgement
                              and will not constitute acceptance of your order.
                              A contract between us will not be formed until we
                              send you confirmation by email that the goods
                              which you ordered have been dispatched to you.
                              Only those goods listed in the confirmation e-mail
                              sent at the time of dispatch will be included in
                              the contract formed.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd5"
                              name="rd"
                            />
                            <label class="tab-label" for="rd5">
                              (b) Pricing and Availability
                            </label>
                            <div class="tab-content">
                              Whilst we try and ensure that all details,
                              descriptions and prices which appear on this
                              website are accurate, errors may occur. If we
                              discover an error in the price of any goods which
                              you have ordered we will inform you of this as
                              soon as possible and give you the option of
                              reconfirming your order at the correct price or
                              cancelling it. If we are unable to contact you we
                              will treat the order as cancelled. If you cancel
                              and you have already paid for the goods, you will
                              receive a full refund. Delivery costs will be
                              charged in addition; such additional charges are
                              clearly displayed where applicable and included in
                              the 'Total Cost'.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd6"
                              name="rd"
                            />
                            <label class="tab-label" for="rd6">
                              (c) Payment
                            </label>
                            <div class="tab-content">
                              Upon receiving your order we carry out a standard
                              authorization check on your payment card to ensure
                              there are sufficient funds to fulfil the
                              transaction. Your card will be debited upon
                              authorization being received. The monies received
                              upon the debiting of your card shall be treated as
                              a deposit against the value of the goods you wish
                              to purchase. Once the goods have been dispatched
                              and you have been sent a confirmation email the
                              monies paid as a deposit shall be used as
                              consideration for the value of goods you have
                              purchased as listed in the confirmation email.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd7"
                              name="rd"
                            />
                            <label class="tab-label" for="rd7">
                              Disclaimer of Liability
                            </label>
                            <div class="tab-content">
                              The material displayed on this website is provided
                              without any guarantees, conditions or warranties
                              as to its accuracy. Unless expressly stated to the
                              contrary to the fullest extent permitted by law{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              and its suppliers, content providers and
                              advertisers hereby expressly exclude all
                              conditions, warranties and other terms which might
                              otherwise be implied by statute, common law or the
                              law of equity and shall not be liable for any
                              damages whatsoever, including but without
                              limitation to any direct, indirect, special,
                              consequential, punitive or incidental damages, or
                              damages for loss of use, profits, data or other
                              intangibles, damage to goodwill or reputation, or
                              the cost of procurement of substitute goods and
                              services, arising out of or related to the use,
                              inability to use, performance or failures of this
                              website or the Linked Sites and any materials
                              posted thereon, irrespective of whether such
                              damages were foreseeable or arise in contract,
                              tort, equity, restitution, by statute, at common
                              law or otherwise. This does not affect{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>
                              's liability for death or personal injury arising
                              from its negligence, fraudulent misrepresentation,
                              misrepresentation as to a fundamental matter or
                              any other liability which cannot be excluded or
                              limited under applicable law.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd8"
                              name="rd"
                            />
                            <label class="tab-label" for="rd8">
                              Linking to this Website
                            </label>
                            <div class="tab-content">
                              You may link to our home page, provided you do so
                              in a way that is fair and legal and does not
                              damage our reputation or take advantage of it, but
                              you must not establish a link in such a way as to
                              suggest any form of association, approval or
                              endorsement on our part where none exists. You
                              must not establish a link from any website that is
                              not owned by you. This website must not be framed
                              on any other site, nor may you create a link to
                              any part of this website other than the home page.
                              We reserve the right to withdraw linking
                              permission without notice. Disclaimer as to
                              ownership of trademarks, images of personalities
                              and third party copyright Except where expressly
                              stated to the contrary all persons (including
                              their names and images), third party trademarks
                              and content, services and/or locations featured on
                              this website are in no way associated, linked or
                              affiliated with{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              and you should not rely on the existence of such a
                              connection or affiliation. Any trade marks/ names
                              featured on this website are owned by the
                              respective trade mark owners. Where a trade mark
                              or brand name is referred to it is used solely to
                              describe or identify the products and services and
                              is in no way an assertion that such products or
                              services are endorsed by or connected to{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>
                              .
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd9"
                              name="rd"
                            />
                            <label class="tab-label" for="rd9">
                              Indemnity
                            </label>
                            <div class="tab-content">
                              You agree to indemnify, defend and hold harmless{" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>
                              , its directors, officers, employees, consultants,
                              agents, and affiliates, from any and all third
                              party claims, liability, damages and/or costs
                              (including, but not limited to, legal fees)
                              arising from your use this website or your breach
                              of the Terms of Service.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd10"
                              name="rd"
                            />
                            <label class="tab-label" for="rd10">
                              Variation
                            </label>
                            <div class="tab-content">
                              {" "}
                              <Link className="text-purple" href="/">
                                {""}
                                {""}www.heavenly-candy.com
                              </Link>{" "}
                              shall have the right in its absolute discretion at
                              any time and without notice to amend, remove or
                              vary the Services and/or any page of this website.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd11"
                              name="rd"
                            />
                            <label class="tab-label" for="rd11">
                              Invalidity
                            </label>
                            <div class="tab-content">
                              If any part of the Terms of Service is
                              unenforceable (including any provision in which we
                              exclude our liability to you) the enforceability
                              of any other part of the Terms of Service will not
                              be affected all other clauses remaining in full
                              force and effect. So far as possible where any
                              clause/sub-clause or part of a clause/sub-clause
                              can be severed to render the remaining part valid,
                              the clause shall be interpreted accordingly.
                              Alternatively, you agree that the clause shall be
                              rectified and interpreted in such a way that
                              closely resembles the original meaning of the
                              clause /sub-clause as is permitted by law.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd12"
                              name="rd"
                            />
                            <label class="tab-label" for="rd12">
                              Complaints
                            </label>
                            <div class="tab-content">
                              We operate a complaints handling procedure which
                              we will use to try to resolve disputes when they
                              first arise, please let us know if you have any
                              complaints or comments.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd13"
                              name="rd"
                            />
                            <label class="tab-label" for="rd13">
                              Waiver
                            </label>
                            <div class="tab-content">
                              If you breach these conditions and we take no
                              action, we will still be entitled to use our
                              rights and remedies in any other situation where
                              you breach these conditions.
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd14"
                              name="rd"
                            />
                            <label class="tab-label" for="rd14">
                              Entire Agreement
                            </label>
                            <div class="tab-content">
                              In no case shall the Heavenly-Candy Brand, The H-C
                              Group, its affiliates, our directors, officers,
                              employees, affiliates, agents, contractors,
                              interns, suppliers, service providers or licensors
                              be liable for any injury, loss, claim, or any
                              direct, indirect, incidental, punitive, special,
                              or consequential damages of any kind, including,
                              without limitation lost profits, lost revenue,
                              lost savings, loss of data, replacement costs, or
                              any similar damages, whether based in contract,
                              tort (including negligence), strict liability or
                              otherwise, arising from your use of any of the
                              service or any products procured using the
                              service, or for any other claim related in any way
                              to your use of the service or any product,
                              including, but not limited to, any errors or
                              omissions in any content, or any loss or damage of
                              any kind incurred as a result of the use of the
                              service or any content (or product) posted,
                              transmitted, or otherwise made available via the
                              service, even if advised of their possibility.
                              Because some states or jurisdictions do not allow
                              the exclusion or the limitation of liability for
                              consequential or incidental damages, in such
                              states or jurisdictions, our liability shall be
                              limited to the maximum extent permitted by law.
                              The above Terms of Service constitute the entire
                              agreement of the parties and supersede any and all
                              preceding and contemporaneous agreements between
                              you and www.heavenlycandy.com. Any waiver of any
                              provision of the Terms of Service will be
                              effective only if in writing and signed by a
                              Director of{" "}
                              <Link className="text-purple" href="/">
                                www.heavenly-candy.com
                              </Link>
                              .
                            </div>
                          </div>

                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd99"
                              name="rd"
                            />
                            <label for="rd99" class="tab-close">
                              Close others &times;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsConditionsPage
